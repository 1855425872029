import React, { useState } from 'react';
import MapPigeon from 'pigeon-maps';
import { IoMdPin } from 'react-icons/io';
import { FaSearchPlus, FaSearchMinus } from 'react-icons/fa';
import castleIcon from 'images/castle.svg';

const Marker = ({ imageSrc, left, top, style, children }) => (
  <div
    style={{
      position: 'absolute',
      left: left,
      top: top,
      ...(style || {}),
    }}
  >
    {imageSrc ? <img src={imageSrc} alt="" /> : children}
  </div>
);

const styleButton = {
  borderRadius: '0.25rem',
  backgroundColor: 'white',
  width: '2rem',
  height: '2rem',
  color: '#454545',
  border: '1px solid #efefef',
  opacity: '0.9',
};

export const Map = () => {
  const [zoom, setZoom] = useState(13);

  return (
    <MapPigeon
      provider={(x, y, z) => {
        const s = String.fromCharCode(97 + ((x + y + z) % 3));
        return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
      }}
      center={[42.892424, 1.809297]}
      zoom={zoom}
      height={500}
    >
      <button
        style={styleButton}
        onClick={() => {
          if (zoom < 19) {
            setZoom(zoom + 1);
          }
        }}
        aria-labelledby="plus-label"
      >
        <span id="plus-label" hidden>
          Zoom +
        </span>
        <FaSearchPlus size={20} />
      </button>
      <button
        style={styleButton}
        onClick={() => {
          if (zoom > 3) {
            setZoom(zoom - 1);
          }
        }}
        aria-labelledby="minus-label"
      >
        <span id="minus-label" hidden>
          Zoom -
        </span>
        <FaSearchMinus size={20} />
      </button>
      <Marker
        anchor={[42.892424, 1.809297]}
        payload={1}
        style={{
          width: '40px',
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IoMdPin size={45} color="#1A99FC" />
          <div
            style={{
              position: 'absolute',
              top: -50,
              marginTop: '0.1rem',
              padding: '0.1rem 0.6rem',
              background: '#fff',
              borderRadius: '0.25rem',
              border: '1px solid #1A99FC',
            }}
          >
            Gites
          </div>
        </div>
      </Marker>
      <Marker
        anchor={[42.8757621, 1.8325]}
        payload={1}
        style={{
          width: '50px',
        }}
        imageSrc={castleIcon}
      />
    </MapPigeon>
  );
};

export default Map;
