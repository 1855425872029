import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { SubTitleHome } from '../shared/styled/headings';
import { Gite } from '../shared/styled/gites';
import Epi from '../Epi/Epi';
import Capacity from '../Capacity/Capacity';
import Bedroom from '../Bedroom/Bedroom';
import Area from '../Area/Area';

const grange = {
  title: 'La grange neuve',
  epis: 3,
  capacity: '6',
  bedroom: '3',
  area: '120',
  description: `Gîte de charme, dans le style du pays, avec une décoration très soignée. Ensoleillé, au calme, vue sur château de Montségur. Station ski Monts d'Olmes à seulement 13 km. Un sentier de montagne part du gîte.`,
};

const GiteGrangeHome = () => {
  const { title, epis, capacity, bedroom, area, description } = grange;

  const imageData = useStaticQuery(graphql`
    query {
      image: file(
        relativeDirectory: { eq: "grange" }
        name: { eq: "interieur_piece_a_vivre_05" }
      ) {
        name
        relativeDirectory
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <SubTitleHome>{title}</SubTitleHome>
      <Gite notAvailable>
        <div className="gite__image">
          <Img fluid={imageData.image.childImageSharp.fluid} alt={title} />
        </div>
        <div className="gite-details">
          <div className="gite-details__header">
            <Epi
              className="gite-details__epis"
              number={epis}
              keyNamespace="clot"
            />
            <Capacity number={capacity} displayNumber />
            <Bedroom number={bedroom} displayNumber />
            <Area area={area} displayNumber />
          </div>
          <p className="gite-details__description">
            <span>{description}</span>
          </p>
          <Link to="/grange-neuve">Voir le gîte</Link>
        </div>
      </Gite>
    </div>
  );
};

export default GiteGrangeHome;
