import React from 'react';
import Helmet from 'react-helmet';
import Slider from 'react-animated-slider';
import { Map } from '../components/Map/Map';
import Layout from '../components/Layout';
import { Content, MapWrapper } from '../components/shared/styled/layout';
import { GitesWrapper } from '../components/shared/styled/gites';
import {
  DecoratedTitle,
  SubTitleHome,
} from '../components/shared/styled/headings';
import { ExternalLink } from '../components/shared/styled/a';
import { img1, img2, img3, img4 } from '../images';
import GiteClotHome from '../components/GiteHomePage/GiteClotHome';
import GiteGrangeHome from '../components/GiteHomePage/GiteGrangeHome';
import text from '../config/content/index.yml';

import '../components/Slider/horizontal.scss';

class CustomSlider extends Slider {
  handleMouseOver = () => {
    return null;
  };

  handleMouseOut = () => {
    return null;
  };
}

class IndexPage extends React.Component {
  render() {
    const content = [img1, img2, img3, img4];
    return (
      <Layout>
        <Helmet>
          <title>Gîtes en Pyrénées Cathares</title>
          <meta
            name="description"
            content="Nos 2 gîtes, à 850m d'altitude à Montferrier dans l'Ariège, se trouvent dans 2 corps de ferme différents dans un hameau calme et très ensoleillé."
          />
        </Helmet>
        <CustomSlider autoplay={4000} touchDisabled>
          {content.map((image, index) => (
            <div
              className="sliderImageWrapper"
              key={index}
              style={{
                background: `url(${image}) no-repeat center center`,
                backgroundSize: 'cover',
              }}
            />
          ))}
        </CustomSlider>

        <Content>
          <DecoratedTitle id="homepage-intro" className="welcome-title">
            {text.intro.title}
          </DecoratedTitle>
          <p>{text.intro.body}</p>
          <GitesWrapper>
            <GiteGrangeHome />
            <GiteClotHome />
          </GitesWrapper>

          <MapWrapper>
            <SubTitleHome>Où sommes-nous ?</SubTitleHome>
            <Map />
          </MapWrapper>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ExternalLink
              style={{ width: 'content-size' }}
              href="https://www.google.com/maps/place/Gîtes+Bertrand+-+gites+de+France/@42.8921914,1.7742081,13z/data=!4m5!3m4!1s0x12af0a5ef460a171:0xb5ebe7bf61f321cc!8m2!3d42.8921871!4d1.8092271"
              text="Voir les gîtes sur Google Maps"
              button
            />
          </div>
        </Content>
      </Layout>
    );
  }
}

export default IndexPage;
