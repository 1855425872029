import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { SubTitleHome } from '../shared/styled/headings';
import { Gite } from '../shared/styled/gites';
import Epi from '../Epi/Epi';
import Capacity from '../Capacity/Capacity';
import Bedroom from '../Bedroom/Bedroom';
import Area from '../Area/Area';

const clot = {
  title: 'Le clot de la fount',
  epis: 4,
  capacity: '6',
  bedroom: '3',
  area: '180',
  description: "Actuellement indisponible, loué à l'année",
};

const GiteClotHome = () => {
  const { title, epis, capacity, bedroom, area, description } = clot;

  return (
    <div>
      <SubTitleHome>{title}</SubTitleHome>
      <Gite notAvailable>
        <div className="gite__image">
          <StaticQuery
            query={graphql`
              query {
                images: file(
                  relativeDirectory: { eq: "clot" }
                  name: { eq: "interieur_piece_a_vivre_01" }
                ) {
                  name
                  relativeDirectory
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={data => <Img fluid={data.images.childImageSharp.fluid} />}
          />
        </div>

        <div className="gite-details">
          <div className="gite-details__header">
            <Epi
              className="gite-details__epis"
              number={epis}
              keyNamespace="clot"
            />
            <Capacity number={capacity} displayNumber />
            <Bedroom number={bedroom} displayNumber />
            <Area area={area} displayNumber />
          </div>
          <p className="gite-details__description">
            <span>{description}</span>
          </p>
        </div>
      </Gite>
    </div>
  );
};

export default GiteClotHome;
